import React from "react";
import {
  ReactJs,
  NodeDotJs,
  Graphql,
  Mongodb,
  Amazonaws,
  Git,
  Github,
  Ruby,
  Rails,
  Postgresql,
  Adobephotoshop,
  Adobeillustrator,
  Adobelightroomclassic,
  Adobepremiere,
  NextDotJs,
  Invision,
  Php,
  Python,
  Tailwindcss,
  Typescript,
} from "@icons-pack/react-simple-icons";
const tech = [
  {
    heading: "CURRENT STACK",
    body: [
      { icon: <Typescript color="#fff" size="2em" />, name: "TypeScript" },

      { icon: <ReactJs color="#fff" size="2em" />, name: "React JS" },
      { icon: <NextDotJs color="#fff" size="2em" />, name: "Next JS" },
      { icon: <Postgresql color="#fff" size="2em" />, name: "PostgresQL" },
      { icon: <Tailwindcss color="#fff" size="2em" />, name: "Tailwind CSS" },
      {
        icon: <Amazonaws color="#fff" size="2em" />,
        name: "Amazon Web Services",
      },
      {
        icon: <Git color="#fff" size="2em" />,
        name: "Git",
      },
      {
        icon: <Github color="#fff" size="2em" />,
        name: "Github",
      },
    ],
  },
  {
    heading: "OTHER FAMILIARITIES",
    body: [
      { icon: <Php color="#fff" size="2em" />, name: "PHP" },
      { icon: <Mongodb color="#fff" size="2em" />, name: "Mongo DB" },
      { icon: <Graphql color="#fff" size="2em" />, name: "GraphQL" },
      { icon: <NodeDotJs color="#fff" size="2em" />, name: "Node JS" },
      { icon: <Invision color="#fff" size="2em" />, name: "Invision" },
      { icon: <Python color="#fff" size="2em" />, name: "Python" },
      { icon: <Ruby color="#fff" size="2em" />, name: "Ruby" },
      { icon: <Rails color="#fff" size="2em" />, name: "Rails" },
      {
        icon: <Adobephotoshop color="#fff" size="2em" />,
        name: "Adobe Photoshop",
      },
      {
        icon: <Adobelightroomclassic color="#fff" size="2em" />,
        name: "Adobe Lightroom",
      },
      {
        icon: <Adobeillustrator color="#fff" size="2em" />,
        name: "Adobe Illustrator",
      },
      {
        icon: <Adobepremiere color="#fff" size="2em" />,
        name: "Adobe Premiere",
      },
    ],
  },
];

export { tech };
