const development = [
  {
    title: "Section-8-Scout",
    link: "https://www.section-8-scout.com",
    desc: "Streamline your Section 8 property investment research with real-time Fair Market Rent validation directly on real estate listing sites.",
    tech: "Next.JS, Drizzle ORM, Tailwind CSS, PostgreSQL, Vercel",
  },
  {
    title: "Freecast",
    repo: "https://github.com/RSpencerFink/freecast",
    link: "https://freecast.vercel.app",
    desc: "Freecast is a modern podcast discovery and analysis platform that allows users to search, explore, and extract insights from podcast content.",
    tech: "Next.JS, Drizzle ORM, Tailwind CSS, PostgreSQL, Vercel, OpenAI, AssemblyAI, iTunes Search API, python",
  },
  {
    title: "react-dynamic-image",
    repo: "https://github.com/RSpencerFink/react-dynamic-image",
    link: "https://www.npmjs.com/package/react-dynamic-image",
    desc: "A lightweight component for cleanly rendering srcSet images in react",
    tech: "React.JS, NPM",
  },
  {
    title: "Concord",
    repo: "https://github.com/RSpencerFink/Concord",
    desc: "A clone of the Discord's text chat, built in React & Ruby on Rails. Completed in a 10-day sprint.",
    tech: "React.JS, Redux, Ruby on Rails, PostgreSQL, Heroku",
  },
  {
    title: "React 2048",
    repo: "https://github.com/RSpencerFink/react-2048",
    link: "",
    desc: "A clone of 2048, built in React.",
    tech: "React.JS",
  },
  {
    title: "BrickBreaker",
    repo: "https://github.com/RSpencerFink/BrickBreaker",
    link: "",
    desc: "A Pong-style brickbreaking game built with vanilla JavaScript.",
    tech: "JavaScript",
  },
];

export { development };
